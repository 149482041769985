<template>
  <section class="comments" aria-labelledby="comment">
    <h2 id="comment">Comments</h2>
    <!-- <Disqus /> -->
  </section>
</template>

<script>
export default {
  name: "comment",
  props: {
    blogId: String,
  },
  created() {},
  data() {
    return {};
  },
};
</script>

<style scoped></style>
